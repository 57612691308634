import { render, staticRenderFns } from "./menarini.vue?vue&type=template&id=a6c03bc0&"
import script from "./menarini.vue?vue&type=script&lang=js&"
export * from "./menarini.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports