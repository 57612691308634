<template>
  <component :is="component" v-if="component" />
</template>

<script>
import gsk from '@/views/booths/gsk.vue';
import pfizer from '@/views/booths/pfizer.vue';
import abbvie from '@/views/booths/abbvie.vue';
import lilly from '@/views/booths/lilly.vue';
import farmanova from '@/views/booths/farmanova.vue';
import menarini from '@/views/booths/menarini.vue';
import msd from '@/views/booths/msd.vue';
import ucb from '@/views/booths/ucb.vue';

export default {
  data() {
    return {
      component: null,
    };
  },
  components: {
    gsk,
    pfizer,
    abbvie,
    lilly,
    farmanova,
    menarini,
    ucb,
    msd,
  },
  computed: {},
  mounted() {
    this.component = this.$options.components[this.$route.params.id];
  },
};
</script>

<style>
</style>
