// UCB Booth
<template>
  <div class="stand">
    <Header />
    <div class="container wide mt-4" v-if="isReady">
      <div class="box">
        <div class="row">
          <div class="col-md-12">
            <img
              class="img-fluid"
              src="/assets/ucb/stand_banner.jpg"
              alt="Stand Banner"
            />
          </div>
        </div>
        <div class="row p-4">
          <div class="col-md-7">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                src="https://player.vimeo.com/video/551837112?loop=1&autoplay=1"
                width="640"
                height="480"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
            <div class="description">
              <p class="mt-4">
                UCB Pharma, bugün €4,6 milyar geliriyle Euronext’e kayıtlı, 40
                ülkede hizmet vermekte olup, tüm dünyada 70 üzeri ülkeden %51’i
                erkek ve %49’u kadın olmak üzere 7.500 çalışana sahiptir.
                Gelirinin %25’ini ARGE’ye ayırmaktadır.
              </p>

              <p>
                Nöroloji ve immünoloji kökenli ağır hastalıkların tedavisi için
                yenilikçi çözümleri keşfetmeye ve geliştirmeye odaklanan UCB
                Pharma, 1990 yılı itibariyle Türkiye’de faaliyet göstermektedir.
                UCB Pharma Türkiye-İstanbul merkez ofisinden Orta Doğu ve Afrika
                pazarları da yönetilmektedir. UCB Pharma, nöroloji ve immünoloji
                alanlarındaki ciddi hastalıklara, bu alanlardaki hastaların
                karşılanmamış ihtiyaçlarına ve onları tedavi eden uzmanlara
                dönük araştırmalarına devam etmektedir. Gelişen pazarlar ve
                Afrika'da epilepsi alanındaki eğitim ile hastalığa ilişkin
                farkındalığı amaçlayan, dünyada ise ekolojik ayak izini
                azaltarak 2030 yılı itibariyle karbonsuz operasyonlar
                gerçekleştirmeyi hedefleyen sosyal sorumluluk anlayışına
                sahiptir. Dünyada 90 yılı geride bırakan UCB Pharma, global bir
                biyoteknoloji şirketidir.
              </p>

              <p><b>UCB Pharma A.Ş.</b><br />Palladium Tower</p>

              <p>
                Barbaros mah. Kardelen sok. No:2 Kat:24/80<br />34746 Ataşehir,
                İstanbul / Turkey
              </p>

              <p>Tel: +90 216 538 0000<br />Fax:+90 216 538 0009</p>

              <p>E-mail: tr-bilgi@ucb.com<br />Web sitesi : www.ucb.com.tr</p>
            </div>
          </div>
          <div class="col-md-5 stand-buttons">
            <div>
              <!--
              <a href="#">
                <img src="@/assets/images/video-call.svg" alt="Canlı Görüşme" />
                <span>Canlı Görüşme</span>
              </a>
              -->
              <a
                v-for="link in booth.links"
                :key="link.name"
                :href="link.url"
                target="_blank"
              >
                <!--<img src="@/assets/images/internet.svg" alt="Web Sitesi" />-->
                <span>{{ link.name }}</span>
              </a>
              <!--
              <a href="/pdf/Humira_Stand_eBrosur_Etkililik.pdf" target="_blank">
                <img src="@/assets/images/product.svg" alt="Ürün Bilgisi" />
                <span>Humira E-Broşür</span>
              </a>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { database } from '@/firebase';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      booth: null,
      isReady: false,
    };
  },
  components: {
    Header,
  },
  computed: {
    ...mapGetters({
      eventCurrentPath: 'event/currentPath',
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
    }),
  },
  methods: {
    ...mapActions({
      getBooth: 'booth/get',
      listBooth: 'booth/find',
    }),
  },
  created() {
    this.getBooth({
      path: `${this.eventCurrentPath}/booth/`,
      id: this.$route.params.id,
    }).then((booth) => {
      this.booth = booth;
      console.log(booth);
      this.isReady = true;
    });

    if (this.currentPeople) {
      const userStatusDatabaseRef = database().ref(
        `/attendees/${this.currentEvent.id}/${this.currentPeople.id}/boothVisit/${this.$route.params.id}`,
      );

      const payload = {
        lastJoin: database.ServerValue.TIMESTAMP,
      };
      userStatusDatabaseRef.once('value').then((snapshot) => {
        if (!snapshot.exists()) {
          payload.firstJoin = database.ServerValue.TIMESTAMP;
        }
        userStatusDatabaseRef.update(payload);
      });
    }
  },
};
</script>

<style lang="scss">
.description {
  p {
    font-size: 14px;
    color: #5e6d82;
  }
}
</style>
